///////////////////////////////////////////////////////////////////////////////////////////////////////

//お知らせ一覧

///////////////////////////////////////////////////////////////////////////////////////////////////////

.c-newslist {
  background-color: $keyColor;
  padding: 15px 15px 15px 100px;
  display: flex;
  align-items: center;
  @include mq-max(sm) {
    //padding: 0 0 14px;
  }
  dl {
    width: 100%;
    display: flex;
    // flex-direction: column;
    align-items: flex-start;

    @include mq-max(sm) {
      flex-direction: column;
      align-items: flex-start;
    }
    dt,dd {
      font-size: 18px;
      font-weight: 300;
      display: inline-block;
    }
    dt {
      display: flex;
      align-items: center;
      //flex: 0 0 170px;
      //max-width: 170px;
      @include mq-max(sm) {
        flex: 0 0 100%;
        max-width: 100%;
      }
    }
    dd {
      flex: 0 0 calc(100% - 170px);
      max-width: calc(100% - 170px);
      font-size: 14px;
      font-weight: 400;
      color: $keyColor;
      @include mq-max(sm) {
        flex: 0 0 100%;
        max-width: 100%;
      }
    }
  }

  a {
    color: $white;
    &[target="_blank"]{
      dd:after{
        @include fa('f35d');
        color: $white;
        margin-left: 10px;
        -webkit-font-smoothing:antialiased;
      }
    }
    &[href$=".pdf"] {
      dd:after {
        @include fa('f1c1');
        font-weight: 400;
        color: $red;
        margin-left: 10px;
        -webkit-font-smoothing:antialiased;
      }
    }
  }


  &__wrap {
    position: relative;
    //@include mq-max(content) {
    //  margin-bottom: 60px;
    //}
    @include mq-max(sm) {
      //margin-bottom: 50px;
      //margin-top: -20px;
    }
  }
  &__heading {
    font-size: 16px;
    font-weight: 600;
    color: $white;
    position: absolute;
    top: 50%;
    left: 30px;
    transform: translateY(-50%);
    @include mq-max(sm) {
      //transform: none;
      //position: static;
      //margin-bottom: 0;
      //padding-left: 15px;
      //font-size: 22px;
    }
  }
  &__toList {
    position: absolute;
    top: 50%;
    right: 35px;
    transform: translateY(-50%);
    line-height: 1;
    font-size: 12px;
    font-weight: 600;
    @include mq-max(sm) {
      top: auto;
      bottom: 5px;
      right: 20px;
      font-size: 14px;
    }
    &:after {
      @include fa('f105');
      position: absolute;
      top: 55%;
      right: -9px;
      transform: translateY(-50%);
      transition: all $transition;
    }
    &:hover {
      &:after {
        right: -12px;
      }
    }
  }
  &__item {
    padding: 5px 10px 7px;
    @include mq-max(sm) {
      //padding: 7px 15px;
    }
    &>a {
      display: flex;
      align-items: center;
      opacity: 1;
      transition: opacity $transition;
      &:after {
        content: none;
      }
      &:hover {
        opacity: .7;
      }
    }
  }
  &__new {
    color: $keyColor;
    font-size: 14px;
    font-weight: 500;
    margin-right: 10px;
  }
  &__date {
    display: inline-block;
    margin-right: 18px;
    font-size: 14px;
    font-weight: 500;
    color: $keyColor;
  }
  .c-label {
    background-color: $keyColor;
    color: $white;
    font-size: 12px;
    padding: 4.5px 5.1px;
    margin-right: 14px;
    text-align: center;
    line-height: 1;
    min-width: 70px;
  }

}