#footer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

}
.l-cv {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @include mq-max(xs) {
    padding: 0 15px;
  }
  &__container {
    color: $white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    .c-btn {
      background-color: transparent;
      border: 1px solid $white;
      color: $white;
    }
  }
  &__logo {
    max-width: 270px;
    margin: 0 auto 65px;
    @include mq-max(sm) {
      margin-bottom: 20px;
    }
    img {
      width: 100%;
    }
  }

  &__title {
    &--white {
      color: white;
      padding-left: 30px;
      line-height: 30px;
      height: 30px;
      background: url('/inc/image/top/bg_ttl-dodecagon__white.png') ;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: 225px 0px;
    }

  }
  &__tel {
    font-size: 32px;
    font-weight: bold;
    position: relative;
    padding-left: 50px;
    @include mq-max(sm) {
      font-size: 20px;
    }
    &::before {
      content: '\f879';
      position: absolute;
      left: 10px;
      font-family: $FontAwesome;
      font-weight: 600;
    }
    &Desc {
      display: flex;
      & dt {
        padding-right: 5px;
      }
    }
  }
  h3 {
    font-weight: normal;
    border: 1px solid $white;
    padding: 15px 50px;
    font-size: 18px;
    @include mq-max(sm) {
      font-size: 16px;
      padding: 10px 50px;
    }
  }
}
.l-f {
  width: 100%;
  padding: 30px 0 0;
  border-top: 1px solid $white;
  @include mq-max(sm) {
    padding: 15px 0 0;
  }
  &--pc {
    display: block;
    @include mq-max(sm) {
      display: none;
    }
  }
  &__inner {
    display: flex;
    justify-content: space-between;
    padding: 0 30px;
    @include mq-max(sm) {
      flex-direction: column;
      padding: 0 15px;
    }
  }
  &__group {
    @include mq-max(sm) {
      margin-bottom: 20px;
    }
    a {
      font-size: 16px;
      @include mq-max(sm) {
        font-size: 14px;
      }
    }
    li {
      &.group{
        font-weight: 600;
        font-size: 18px;
        color: $keyColor;
        @include mq-max(sm) {
          font-size: 16px;
        }
      }
      &:not(:first-child) {
        margin-top: 10px;
      }
    }
    img {
      max-width: 200px;
    }
    &--top {
      li {
        &.group{
          color: $white;
          font-weight: 600;
          font-size: 18px;
          @include mq-max(sm) {
            font-size: 16px;
          }
        }
        &:not(:first-child) {
          margin-top: 10px;
        }
      }
      a {
        color: $white;
      }
    }
  }
  &__logo {
    max-width: 270px;
    img {
      width: 100%;
    }
  }
  //Copyright
  &__copyright {
    font-size: 15px;
    text-align: center;
    display: block;
    padding: 30px;
    @include mq-max(sm) {
      font-size: 10px;
      padding: 18px 5px;
    }
    &--top {
      color: $white;
      font-size: 15px;
      text-align: center;
      display: block;
      padding: 30px;
      @include mq-max(sm) {
        font-size: 10px;
        padding: 18px 5px;
      }
    }
  }


  //下層フッター
  &--lineTop {
    border-top: 1px solid $gray;
  }
}

