.l-cv {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: $keyColor;
  @include mq-max(xs) {
    padding: 0 15px;
  }
  &__container {
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    .c-btn {
      background-color: transparent;
      border: 1px solid $white;
      color: $white;
    }
  }
  &__flex {
    display: flex;
    justify-content: center;
    &>.l-cv__container {
      padding: 0 15px;
    }
  }
  &__logo {
    max-width: 270px;
    margin: 0 auto 65px;
    @include mq-max(sm) {
      margin-bottom: 20px;
    }
    img {
      width: 100%;
    }
  }
  &__title {
    color: $white;
    small {
      color: $white;
    }
  }
  &__tel {
    font-size: 32px;
    font-weight: bold;
    position: relative;
    padding-left: 50px;
    @include mq-max(sm) {
      font-size: 20px;
    }
    &::before {
      content: '\f879';
      position: absolute;
      left: 10px;
      font-family: $FontAwesome;
      font-weight: 600;
    }
    &Desc {
      display: flex;
      & dt {
        padding-right: 5px;
      }
    }
  }
  h3 {
    font-weight: normal;
    padding: 15px 50px;
    font-size: 18px;
    @include mq-max(sm) {
      font-size: 16px;
      padding: 10px 50px;
    }
  }
  //TOP
  &--top {
    color: $white;
    background-color: transparent;
  }
}


