.l-header {

  &.scroll-color{
    background: linear-gradient(180deg, #ffffff, transparent);

    .logo{
      span{
        color: #000660;
        border-left: 1px solid #000660;
      }
    }
  }

  // background-color: $white;
  @if $is-fixed == true {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1002;
    height: $headerHeight;
  }
  &__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
    padding: 0 0 0 34px;
    position: relative;
    z-index: 100;
    //box-shadow: 0px 0px 10px rgba(0,0,0,0.16);
    background-color: transparent;
    @include mq-max(md) {
      display: block;
      padding: 0;
      position: fixed;
      top: 0;
      background: transparent;
      box-shadow: none;
    }
  }

  h1,.logo {
    margin-bottom: 0;
    margin-right: 30px;
    line-height: 0;
    display: inline-block;
    width: 289.5px; // 状況によって変更可
    @include mq-max(content) {
      width: 160px;
    }
    @include mq-max(md) {
      width: 145px;
      margin: 0;
      line-height: 0;
    }
    img {
      width: 100%;
    }
  }
  .logo {
    img {
      opacity: 1;
      transition: opacity $transition;
    }
    &:hover {
      img {
        opacity: .7;
      }
    }
  }

  &__modules {
    position: relative;
    line-height: 0;
    @include mq-max(md) {
      padding: 20.7px 15px;
      //background-color: $white;
      line-height: 0;
      box-shadow: 0px 0px 10px rgba(0,0,0,0.16);
    }
  }
}

  ///////////////////////////////////////////////////////////////////////////////////////////////////////

  //  ハンバーガーメニュー

  ///////////////////////////////////////////////////////////////////////////////////////////////////////
.c-toggleBtn {
  display: block;
  position: absolute;
  top: 50%;
  right: 130px;
  transform: translateY(-50%);
  width: 42px;
  height: 42px;
  cursor: pointer;
  z-index: 1003;
  @include mq-max(md) {
    right: 15px;
  }
  span{
    display: block;
    position: absolute;
    width: 30px;
    border-bottom: solid 1px $white;
    transition: .35s ease-in-out; //変化の速度を指定
    left: 6px;
    &:nth-child(1){
      top: 9px;
    }
    &:nth-child(2){
      top: 18px;
      width: 20px;
    }
    &:nth-child(3){
      top: 27px;
    }
  }

  &.scroll-color{
    span{
      border-bottom: 1px solid #000000;
    }
  }

  &.is-active{
    span{
      border-bottom: solid 1px $keyColor;
      &:nth-child(1){
        top: 18px;
        left: 6px;
        transform: rotate(-45deg);
      }
      &:nth-child(2),&:nth-child(3){
        top: 18px;
        transform: rotate(45deg);
      }
      &:nth-child(2) {
        left: 11px;
      }
    }

    a{
      &.current{
        position: relative;
        &:before{
          content: "";
          position: absolute;
          bottom: 0;
          border-bottom: 1px solid #000660;
        }
      }
    }
  }
}
