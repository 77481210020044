///////////////////////////////////////////////////////////////////////////////////////////////////////

//  見出しクラス

///////////////////////////////////////////////////////////////////////////////////////////////////////

.c-ttl {
  //h1見出し

  &-1 {
    font-size: 38px;
    font-weight: 500;
    margin-bottom: 20px;
    @include mq-max(sm) {
      font-size: 32px;
    }
    @include mq-max(xs) {
      font-size: 28px;
    }
    &>small {
      display: block;
      font-size: 18px;
      font-weight: 600;
      color: $keyColor;
      letter-spacing: 0.06em;
      margin-bottom: 5px;
    }
  }

  //h2見出し
  &-2 {
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 10px;
    @include mq-max(xs) {
      font-size: 28px;
    }
    &>small {
      display: block;
      font-size: 18px;
      font-weight: 600;
      color: $keyColor;
      letter-spacing: 0.06em;
      margin-bottom: 5px;
    }
    &--line {
      padding-right: 70px;
      &>span {
        display: inline-block;
        position: relative;
        &:before,
        &:after {
          content: '';
          display: inline-block;
          height: 2px;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
        }
        &:before {
          width: 54px;
          background-color: $gray;
          right: -1 * (54px + 9px);
        }
        &:after {
          width: 27px;
          right: -1 * (27px + 9px);
          background-color: $keyColor;
        }
      }
    }
  }

  //h3見出し
  &-3 {
    font-size: 20px;
    margin-bottom: 10px;
    font-weight: 600;
    @include mq-max(xs) {
      font-size: 20px;
    }
    &>small {
      display: block;
      font-size: 18px;
      font-weight: 600;
      color: $keyColor;
      letter-spacing: 0.06em;
      margin-bottom: 5px;
    }
    &--line {
      position: relative;
      padding-left: 12px;
      &:before,
      &:after {
        content: '';
        display: inline-block;
        width: 2px;
        position: absolute;
        left: 0;
      }
      &:before {
        background-color: $gray;
        height: 100%;
        top: 50%;
        transform: translateY(-50%);
      }
      &:after {
        background-color: $keyColor;
        top: 0;
        height: 50%;
      }
    }
  }

  //modify
  &--center {
    text-align: center;
  }
  &--mbLarge {
    margin-bottom: 30px;
  }
  &--client {
    &>small {
      color: $accentColor;
    }
  }
  &--white {
    color: $white;
    // text-shadow: 2px 3px 10px rgba(0,0,0,0.2);
  }
  &--black {
    color: $black;
    // text-shadow: 2px 3px 10px rgba(0,0,0,0.2);
  }
  &--fullimg {
    font-size: 33px;
    letter-spacing: .15em;
    @include mq-max(sm) {
      font-size: 20px;
      letter-spacing: .15em;
    }
  }
  &--balloon {
    display: flex;
    align-items: center;
    & span {
      flex-shrink: 0;
      position: relative;
      display: inline-block;
      margin-right: 10px;
      padding: 0 5px;
      width: 55px;
      height: 55px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $white;
      font-size: 25px;
      font-weight: bold;
      background: $keyColor;
      border-radius: 50%;
      &::before {
        content: "";
        position: absolute;
        bottom: -10px;
        right: -10px;
        margin-top: -15px;
        border: 15px solid transparent;
        border-left: 15px solid $keyColor;
        z-index: 0;
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    }
  }
  &--number {
    position: relative;
    &::before {
      content: attr(data-number);
      display: inline-block;
      margin-right: 20px;
      color: $keyColor;
      font-size: 30px;
      border-bottom: 1px solid $keyColor;
    }
  }
}

//章見出し
.c-ttlChapter {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 30px;
  text-align: center;
  padding: 15px 10px;
  background-color: $lightGray;
  transform: skew($skew);
  @include mq-max(sm) {
    padding: 15px 24px;
  }
  @include mq-max(xs) {
    font-size: 20px;
  }
  &>span {
    display: inline-block;
    transform: skew(-1 * $skew);
  }

  //章小見出し
  &--sub {
    font-size: 21px;
    font-weight: 500;
    background: none;
    padding: 4px 10px;
    margin-bottom: 23px;
    transform: none;
    position: relative;
    &:before {
      content: '';
      width: 100%;
      height: 1px;
      display: block;
      background-color: $keyColor;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      z-index: 1;
    }
    @include mq-max(xs) {
      font-size: 18px;
    }
    &>span {
      position: relative;
      transform: none;
      padding: 0 15px;
      background-color: $white;
      z-index: 2;
    }
  }
}